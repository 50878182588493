import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const LoginAuth = ({ children }) => {
  const user = useSelector((state) => state.user.user);
  const isAuthanticated = useSelector((state) => state.user.isAuthantication);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isback = searchParams.get('isback') ? true : false;
  const backid = searchParams.get('isback') ? searchParams.get('id') : null;
  console.log(isback , backid , "1111111111111111111111111111111111111")


  if (Object.keys(user)?.length > 0) {
    if (user?.firstName && isAuthanticated === true) {
      if(isback){
        return
      }else{
        return <Navigate replace to="/user/profile" />;
      }
    }
  } else {
    return <>{children}</>;
  }
};

export default LoginAuth;

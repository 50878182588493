import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UserLogin } from "../../redux/actions/User";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import { FiLock } from "react-icons/fi";
import LoginAuth from "../PrivateRoutes/LoginAuth";
import "./Login.css";
import Loginbox from "./Loginbox";

const Login = () => {
 

  return (
    <LoginAuth>
      <div className="bg-color1 my-2 w-full flex justify-center place-items-center py-10 min-h-[100vh]">
       <Loginbox/>
      </div>
    </LoginAuth>
  );
};

export default Login;

import axios from "axios";

import {
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_REQUEST_FAIL,
  FORGOT_PASSWORD_SUCCESS,
  GET_USER_LOCATION,
  LOGIN_USER_FAIL,
  LOGIN_USER_REQUEST,
  LOGIN_USER_REQUEST_FAIL,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER_REQUEST,
  LOGOUT_USER_SUCCESS,
  TOKEN_VERIFY_FAIL,
  TOKEN_VERIFY_REQUEST,
  TOKEN_VERIFY_REQUEST_FAIL,
  TOKEN_VERIFY_SUCCESS,
  UPDATE_USER_PASSWORD_ERROR,
  UPDATE_USER_PASSWORD_FAIL,
  UPDATE_USER_PASSWORD_REQUEST,
  UPDATE_USER_PASSWORD_SUCCESS,
  UPDATE_USER_PROFILE_ERROR,
  UPDATE_USER_PROFILE_FAIL,
  UPDATE_USER_PROFILE_REQUEST,
  UPDATE_USER_PROFILE_SUCCESS,
  USER_SIGNUP_FAIL,
  USER_SIGNUP_REQUEST,
  USER_SIGNUP_REQUEST_FAIL,
  USER_SIGNUP_SUCCESS,
} from "../constant/User";
import { ApiURL } from "../../setting/GlobleVariable";
import toast from "react-hot-toast";

// ----- create user
export const UserSignUp = (formdata, navigate , isLoginModal , setActive) => async (dispatch) => {
  try {
    dispatch({ type: USER_SIGNUP_REQUEST });
    // const res = await axios.post(`${ApiURL}/user/registration`, formdata);
    const res = await fetch(`${ApiURL}/user/registration`, {
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
      },
      body: formdata,
    });
    dispatch({ type: USER_SIGNUP_REQUEST_FAIL });
    const data = await res.json();
    if (res.status === 400) {
      return toast.error(data.message, { duration: 5000 });
    }
    if (res.status === 200) {
      dispatch({ type: USER_SIGNUP_SUCCESS });
      toast.success(data.message, { duration: 3000 });
      if(isLoginModal){
        setActive(1)
      }else{
        navigate("/login");
      }
    }
  } catch (error) {
    dispatch({ type: USER_SIGNUP_FAIL });
  }
};

// ---------------------- user login
export const UserLogin = (email, password, navigate, isModelogin ,isSetModel  ) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_USER_REQUEST });
    const res = await fetch(`${ApiURL}/user/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email, password: password }),
    });

    dispatch({ type: LOGIN_USER_REQUEST_FAIL });
    const data = await res.json();
    if (res.status === 400) {
      return toast.error(data.message);
    } else {
      toast.success(data.message);
      dispatch({ type: LOGIN_USER_SUCCESS, payload: data.user });
      localStorage.setItem("instapass_user_token", data.Token);
      if(isModelogin){
        isSetModel(false);
      }else{
        navigate("/");
      }
    }
  } catch (error) {
    dispatch({ type: LOGIN_USER_FAIL });
  }
};

// ----- tokrn verify
export const TokenVerify = () => async (dispatch) => {
  try {
    dispatch({ type: TOKEN_VERIFY_REQUEST });

    const res = await fetch(`${ApiURL}/user/verify`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("instapass_user_token"),
      },
    });
    dispatch({ type: TOKEN_VERIFY_REQUEST_FAIL });
    const data = await res.json();
    if (res.status === 400) {
      return;
    } else {
      dispatch({ type: TOKEN_VERIFY_SUCCESS, payload: data.User });
    }
  } catch (error) {
    dispatch({ type: TOKEN_VERIFY_FAIL });
  }
};

// ---- logout User
export const LogoutUserFunc = (navigate) => async (dispatch) => {
  dispatch({ type: LOGOUT_USER_REQUEST });
  localStorage.removeItem("instapass_user_token");
  dispatch({ type: LOGOUT_USER_SUCCESS });
  toast.success("Successfully logged out", { duration: 3000 });
  navigate("/");
};

// ============ update user profile
export const UpdateUserProfile = (formdata, navigate) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_USER_PROFILE_REQUEST });
    const res = await fetch(`${ApiURL}/user/update/profile`, {
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        token: localStorage.getItem("instapass_user_token"),
      },
      body: formdata,
    });
    dispatch({ type: UPDATE_USER_PROFILE_FAIL });
    const data = await res.json();
    console.log(data);
    if (res.status === 400) {
      return toast.error(data.message, { duration: 5000 });
    }
    if (res.status === 200) {
      dispatch({ type: UPDATE_USER_PROFILE_SUCCESS });
      toast.success(data.message, { duration: 3000 });
      // navigate("/login");
    }
  } catch (error) {
    dispatch({ type: UPDATE_USER_PROFILE_ERROR });
  }
};

// ===== update user password
export const UpdateUserPassword =
  (oldPassword, newPassword) => async (dispatch) => {
    try {
      console.log(oldPassword, newPassword);
      dispatch({ type: UPDATE_USER_PASSWORD_REQUEST });
      const res = await fetch(`${ApiURL}/user/update/password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("instapass_user_token"),
        },
        body: JSON.stringify({ oldPassword, newPassword }),
      });
      dispatch({ type: UPDATE_USER_PASSWORD_FAIL });
      const data = await res.json();
      console.log(data);
      if (res.status === 400) {
        return toast.error(data.message, { duration: 5000 });
      }
      if (res.status === 200) {
        dispatch({ type: UPDATE_USER_PASSWORD_SUCCESS });
        toast.success(data.message, { duration: 3000 });
        // navigate("/login");
      }
    } catch (error) {
      dispatch({ type: UPDATE_USER_PASSWORD_ERROR });
    }
  };

export const GetUserLocationFunc = (location) => async (dispatch) => {
  dispatch({ type: GET_USER_LOCATION, payload: location });
};

// ---- forgot password
export const ForgotPassword = (email, setIsOTPVerify) => async (dispatch) => {
  try {
    dispatch({ type: FORGOT_PASSWORD_REQUEST });

    const res = await fetch(`${ApiURL}/user/forgot/OTP/send`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email }),
    });

    dispatch({ type: FORGOT_PASSWORD_REQUEST_FAIL });
    const data = await res.json();
    if (res.status === 400) {
      return toast.error(data.message);
    } else {
      toast.success(data.message);
      setIsOTPVerify(true);
      dispatch({ type: FORGOT_PASSWORD_SUCCESS });
    }
  } catch (error) {
    dispatch({ type: FORGOT_PASSWORD_ERROR });
  }
};

// reset password
export const ResetPassword =
  (OTP, NewPassword, navigate) => async (dispatch) => {
    try {
      dispatch({ type: FORGOT_PASSWORD_REQUEST });

      const res = await fetch(`${ApiURL}/user/resetpassword`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ OTP, NewPassword }),
      });

      dispatch({ type: FORGOT_PASSWORD_REQUEST_FAIL });
      const data = await res.json();
      if (res.status === 400) {
        return toast.error(data.message);
      } else {
        toast.success(data.message);
        navigate("/");
        dispatch({ type: FORGOT_PASSWORD_SUCCESS });
      }
    } catch (error) {
      dispatch({ type: FORGOT_PASSWORD_ERROR });
    }
  };

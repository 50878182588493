import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { FaShareAlt } from "react-icons/fa";
import ShareModal from "./ShareModal";
import { LuClipboardEdit } from "react-icons/lu";
import { RxCross1 } from "react-icons/rx";
import {
  CreateEventByAdmin,
  CreateEventByAdminFeatured,
} from "../../../redux/actions/Admin/AdminActions";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { Loading } from "notiflix";
import MainVerctor1 from "../../../assets/svg/VectorMain.svg";
import RedOverLay from "../../../assets/svg/VectorRed.svg";
import WhiteOverLay from "../../../assets/svg/Vectorwhite.svg";
import instaLogo from "../../../assets/deafullogo.png";
import "./LatestEventCard.css";

const FeaturedEvent = ({ item, index }) => {
  const [Share, setShare] = useState(false);
  const user = useSelector((state) => state.user.user);
  const [createevent, setcreateevent] = useState(false);
  const [Paragraph, setParagraph] = useState("");
  const [image, setImage] = useState(null);
  const [modal, setModal] = useState(1);
  const [event, setEvent] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleNavigation = (eventId, dataToSend) => {
    navigate(`/tickets/group.details/${eventId}`, {
      state: { data: [dataToSend] },
    });
  };

  const uploadImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const createeventFunction = async () => {
    if (modal === 1) {
      if (!Paragraph || !event) {
        return toast.error("Please Enter All fields");
      }
      const formdata = new FormData();
      const url = "create/feature";
      formdata.append("event", JSON.stringify(event));
      formdata.append("paragarph", Paragraph);
      formdata.append("isFeatured", true);
      await dispatch(CreateEventByAdminFeatured(formdata, url));
      setEvent({});
      setParagraph("");
      setcreateevent(false);
    } else {
      if (!Paragraph || !event || !image) {
        return toast.error("Please Enter All fields");
      }
      const formdata = new FormData();
      const url = "create";
      formdata.append("event", JSON.stringify(event));
      formdata.append("paragarph", Paragraph);
      formdata.append("file", image);
      formdata.append("isFeatured", false);
      await dispatch(CreateEventByAdmin(formdata, url));
      setEvent({});
      setParagraph("");
      setImage(null);
      setcreateevent(false);
    }
  };

  const adminloading = useSelector((state) => state.Admin.adminloading);
  useEffect(() => {
    if (adminloading === true) {
      Loading.standard("Loading Plaese Wait");
    } else {
      Loading.remove();
    }
  }, [adminloading]);


  return (
    <>
      <div
        className={`LatestEventCardNewCardDesign
    ${index % 4 === 0 || index % 5 === 0
            ? "!bg-[rgba(0,0,0,.4)]"
            : index % 3 === 0
              ? "bg-white"
              : index % 2 === 0
                ? " bg-[#5C5C5C2B]"
                : "bg-[#270A0A]"
          }
    ${index % 4 === 0 ? "border-[1px] border-[rgba(255, 255, 255, 1)" : ""}
    ${index % 5 === 0 ? "border-[1px] border-[#8A0000]" : ""}
   
     px-[15px] py-[5px] flex justify-center gap-[15px] pt-[20px] place-items-center flex-col relative`}

      >
        <div className="relative ">
          <div className="relative !overflow-hidden mx-auto w-[275px] h-[304px]">
            <img
              src={MainVerctor1}
              alt=""
              className="px-[9px] !z-[25] "
            />
            <img
              src={
                index % 6 === 0
                  ? RedOverLay
                  : index % 2 === 0
                    ? WhiteOverLay
                    : RedOverLay
              }
              alt=""
              className="!px-[9px] relative !z-[22] imageoverlay"
            />
          </div>
          <div
            className={` w-[40px] h-[28px] absolute top-[-4px] left-[10px] card_uper_vector1 z-[7]`}
          >
            <FaShareAlt
              className={`text-[20px]  ${index % 2 === 0
                ? "text-[#8a0000]"
                : index % 3 === 0
                  ? "text-black"
                  : "text-[white]"
                } cursor-pointer`}
              onClick={() => setShare(!Share)}
            />
          </div>
          {user && user.email && user.role === "admin" && (
            <LuClipboardEdit
              className="absolute top-[50px] right-5 text-[25px] cursor-pointer text-[black] z-[888]"
              onClick={() => {
                setcreateevent(true);
                setEvent(item);
              }}
            />
          )}
          <div
            className={` ${index % 6 === 0
              ? "bg-[#8A0000]"
              : index % 4 === 0
                ? "bg-white"
                : index % 3 === 0
                  ? "bg-black"
                  : index % 2 === 0
                    ? "bg-white"
                    : "bg-[#8A0000]"
              }
          w-[51px] h-[51px] absolute right-[10px] bottom-[2px] card_bottom_vector1`}
          ></div>
          {/* <div className="absolute right-[-10px] bottom-[2px] pb-[2px] flex justify-end place-items-center gap-1 flex-col">
            {data?.map((item, i) => {
              return (
                <div
                  key={i}
                  className={`h-[14px] w-[15px]  rounded-sm
                ${
                  index % 6 === 0
                    ? "bg-[#8A0000]"
                    : index % 3 === 0
                    ? "bg-black"
                    : index % 2 === 0
                    ? "bg-white"
                    : "bg-[#8A0000]"
                }
              `}
                >
                  {" "}
                </div>
              );
            })}
          </div> */}
          <img
            src={instaLogo}
            alt="image"
            className="absolute top-2 right-3 w-[61px] h-[36px]"
          />
          <div
            className={`w-[93%] py-2 z-50 bg-transparent min-h-[100px]
        absolute left-0 bottom-0 mx-[10px] pl-[6px]
        ${index % 2 === 0 ? "text-[black]" : "text-[white]"}
        `}
            onClick={() => handleNavigation(item?.id, item)}
          >
            <p className="Name_of_the_event mb-1 leading-tight">
              {item && item?.name && item?.name?.length > 30
                ? `${item?.name?.slice(0, 30)}...`
                : item?.name}
            </p>
            <p className="Time_stamp_event">
              {item &&
                item &&
                item?.occurs_at &&
                moment(item?.occurs_at)
                  .utcOffset("+01:00")
                  .format("h:mm A")
                  ?.split("+", 1)}
            </p>
            <p className="live_location_event uppercase !text-[14px]">
              LIVE IN -{" "}
              {item && item && item && item?.venue?.location?.slice(0, 16)}
            </p>
          </div>
        </div>
        {/* --- footer  */}
        <div
          className={`new_card_footer px-[3px] w-[100%] mt-[10px] flex justify-start place-items-start gap-2 ${index % 5 === 0
            ? "!text-white"
            : index % 4 === 0
              ? "!text-white"
              : index % 3 === 0
                ? "text-black"
                : "text-white"
            }`}
          onClick={() => handleNavigation(item?.id, item)}
        >
          {/* --- button  */}
          <button className="new_card_btn !text-white">Get Tickets</button>
          <div>
            <p className="time_text_of_new_card">Time</p>
            <p
              className={`time_of_new_card ${index === 5
                ? "bg-white"
                : index % 3 === 0
                  ? "!bg-black"
                  : "!bg-white"
                }
            
            ${index % 3 === 0 ? "!text-white" : "!text-black"}
            `}
            >
              {item &&
                item?.occurs_at &&
                moment(item?.occurs_at).format("hh:mm A")}
            </p>
          </div>
          <span className="w-[2px] h-[37px] bg-color2"></span>
          <div className="flex justify-start place-items-end gap-2">
            <p className="date_of_new_card">
              {item && item?.occurs_at && moment(item?.occurs_at).format("DD")}
            </p>
            <p className="month_of_new_card">
              {item && item?.occurs_at && moment(item?.occurs_at).format("MMM")}
            </p>
          </div>
        </div>
      </div>
      {/* ========== share modal  */}
      {Share && <ShareModal setShow={setShare} item={item} Share={Share} />}
      {/* ===  create event modal  */}
      {createevent && (
        <div className="z-[999999] fixed left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-50 py-10">
          <div className="max-h-full relative w-full max-w-xl overflow-y-auto sm:rounded-2xl bg-color4">
            <div className="w-full">
              <div className="py-16 px-5">
                <div className="">
                  <RxCross1
                    className="absolute top-4 right-4 text-[25px] cursor-pointer"
                    onClick={() => {
                      setcreateevent(false);
                      setEvent({});
                      setParagraph("");
                      setImage(null);
                    }}
                  />

                  {/* --------- paragrapg  */}
                  <div className="w-[100%] my-5">
                    <label
                      htmlFor="heading"
                      className="w-full block mb-1 pl-1 "
                    >
                      Carousal Paragraph
                    </label>
                    <input
                      value={Paragraph}
                      onChange={(e) => setParagraph(e.target.value)}
                      type="text"
                      placeholder="Please Enter Paragraph"
                      className="py-2 px-3 bg-color4 border-[1px] border-color5 rounded-md outline-none w-full"
                    />
                  </div>
                  {/* ------ image  */}
                  {modal === 2 && (
                    <div className="w-[100%] my-5">
                      <label
                        htmlFor="heading"
                        className="w-full block mb-1 pl-1 "
                      >
                        Carousal Image
                      </label>
                      <input
                        onChange={uploadImageChange}
                        type="file"
                        accept="image/*"
                        multiple="fase"
                        placeholder="Please Enter Paragraph"
                        className="py-2 px-3 bg-color4 border-[1px] border-color5 rounded-md outline-none w-full"
                      />
                    </div>
                  )}
                  <button
                    className="mt-2 px-3 py-3 bg-color1 text-color2 w-full text-[17px] rounded-md"
                    onClick={createeventFunction}
                  >
                    Create
                  </button>
                  <p
                    className="cursor-pointer mt-5 text-center text-[gray]"
                    onClick={() => {
                      if (modal === 1) {
                        setModal(2);
                      } else {
                        setModal(1);
                      }
                    }}
                  >
                    create {modal === 1 ? "Latest Event" : "Trending Event"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FeaturedEvent;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "./Header/Header";
import "./Index.css";
import Sidebar from "./Sidebar/Sidebar";
import Loadingcom from "../../Loading/Loading";
import { Tevomaps } from "@ticketevolution/seatmaps-client";
import { CreateOrder, GetAllEvents } from "../../../redux/actions/Events";
import noChart from "../../../assets/NoMap.gif";
import CreditCardPayment from "../../Payments/CreditCardPayment";
import { RxCross1 } from "react-icons/rx";
import PayPalPayment from "../../Payments/PayPalPayment";
import Spinner from "../../Loading/Spinner";
import { Loading } from "notiflix";
import GooglePay from "../../Payments/GooglePay";
import { useLocation, useNavigate } from "react-router-dom";
import ApplePay from "../../Payments/ApplePay";
import { ApplyCoupon, RemoveCoupon } from "../../../redux/actions/CouponCode";
import NewPaymentPage from "../../../pages/NewPaymentPage";

const Index = ({ id }) => {
  const user = useSelector((state) => state.user.user);
  const [payWith, setPayWith] = useState(0);
  const [PaymentNonce, setPaymentNonce] = useState("");
  const [data, setdata] = useState(null);
  const [coupon, setcoupon] = useState("");
  const location = useLocation();
  const receivedData = location.state?.data;
  const reviceImage = location?.state?.image;
  const [isPayment, setIsPayment] = useState(false)
  const [SingleTicket, setSingleTicket] = useState([]);
  const [PaymentInfoData, setPaymentInfoData] = useState({
    email: user?.email ? user?.email : "",
    fname: user?.firstName ? user?.firstName :"",
    lname: user?.lastName ? user?.lastName : "",  
  });

  useEffect(()=>{
     setPaymentInfoData({
      email: user?.email ? user?.email : "",
      fname: user?.firstName ? user?.firstName :"",
      lname: user?.lastName ? user?.lastName : "",  
    })
  },[user])

  const dispatch = useDispatch();
  const naviate = useNavigate();


  const placeOrder = async () => {
    const offline = user?.email ? false : true;
    dispatch(
      await CreateOrder(
        data && data?.id,
        data && data.qty,
        data && data?.retail_price,
        data && data?.format,
        id,
        user?.clientId,
        user?.emailAddressId,
        dispatch,
        naviate,
        setIsPayment,
        offline,
        PaymentInfoData.fname,
        PaymentInfoData.email,
        PaymentInfoData.lname
         
      )
    );

  };


  const getOrderData = (orderdata) => {
    setdata(orderdata);
  };
  const isLoading = useSelector((state) => state.order.isloading);
  const Loadingevent = useSelector((state) => state.events.isLoading);

  useEffect(() => {
    if (!isPayment) {
      if (isLoading === true || Loadingevent === true) {
        Loading.standard("Loading Plaese Wait", {
          svgColor: "#CD292E",
        });
      } else {
        Loading.remove();
      }
    }

  }, [isLoading, Loadingevent]);

  const GroupsEvents = useSelector((state) => state.events.EventDetails);
  const AllEvents = useSelector((state) => state.events.AllEvents);
  const serachevents = useSelector((state) => state.events.serachevents);
  var filtertheEvent =
    serachevents?.length > 0
      ? serachevents &&
      GroupsEvents &&
      serachevents?.filter((item) => item.id == id)
      : AllEvents && GroupsEvents && AllEvents?.filter((item) => item.id == id);
  const AllEventLoading = useSelector((state) => state.events.AllEventLoading);
  const [isImage, setIsImage] = useState("");

  //   ---- append the div in the body
  // useEffect(() => {
  //   if (filtertheEvent) {
  //     // Find the first element with the class "parent_seat_map_wraper"
  //     var seatWrapperParent = document.querySelector(".parent_seat_map_wraper");

  //     // Check if the parent div exists
  //     if (seatWrapperParent && !AllEventLoading) {
  //       // Create a new div element
  //       var parentDiv = document.createElement("div");
  //       // parentDiv.classList.add("map_wrapper");

  //       // Check if the parent div was created successfully
  //       // if (parentDiv) {
  //       // Create a new div element
  //       var newDiv = document.createElement("div");
  //       newDiv.id = "map";
  //       newDiv.classList.add("mapclass");

  //       // Append the new div as a child to the created parent div
  //       // parentDiv.appendChild(newDiv);

  //       // Append the parent div as a child to the seatWrapperParent
  //       seatWrapperParent.appendChild(newDiv);
  //       // }
  //     } else {
  //       var myElement = document.getElementById("map");
  //       console.log("hello world!");
  //       // Hide the element by setting its display property to "none"
  //       if (myElement) {
  //         // myElement.style.setProperty("display", "none", "important");
  //       }
  //     }
  //   }
  // }, [filtertheEvent]);

  //   console.log(GroupsEvents);
  // useEffect(() => {
  //   const seatWrapperParent = document.querySelector(".parent_seat_map_wraper");

  //   if (seatWrapperParent && !AllEventLoading) {
  //     const existingMapDiv = document.getElementById("map");

  //     if (!existingMapDiv) {
  //       const newDiv = document.createElement("div");
  //       newDiv.id = "map";
  //       newDiv.classList.add("mapclass");

  //       seatWrapperParent.appendChild(newDiv);
  //     }
  //   }
  // }, [AllEventLoading]);
  // useEffect(() => {
  //   var seatWrapperParents = document.querySelectorAll(
  //     ".parent_seat_map_wraper"
  //   );

  //   if (seatWrapperParents && seatWrapperParents) {
  //     if (filtertheEvent && filtertheEvent?.length > 0 && !AllEventLoading) {
  //       const finddiv = document.getElementById("map");
  //       const parentdic = document.querySelectorAll(".map_wrapper");
  //       // create a new seatmap
  //       if (finddiv && parentdic) {
  //         const seatmap = new Tevomaps({
  //           venueId: filtertheEvent[0]?.venue?.id,
  //           configurationId: filtertheEvent[0]?.configuration?.id,
  //           ticketGroups: GroupsEvents.map((item) => ({
  //             tevo_section_name: item?.section,
  //             retail_price: item?.retail_price,
  //           })),
  //           showControls: false,
  //           showLegend: false,
  //         });

  //         console.log("Tevomaps instance created:", seatmap);

  //         // turn element with ID of 'my-map' into a seatmap for config 1046
  //         const seatmapApi = seatmap.build("map");

  //         console.log("Tevomaps API built:", seatmapApi);
  //       }
  //     } else {
  //       filtertheEvent = [];
  //     }
  //   }
  // }, [filtertheEvent]);

  useEffect(() => {
    const page = 1;
    if (AllEvents && AllEvents?.length > 0) {
      dispatch(GetAllEvents(page));
    }
  }, []);
  // console.log(receivedData);

  // useEffect(() => {
  //   const imageUrl =
  //     filtertheEvent && filtertheEvent[0]?.configuration?.seating_chart?.large;
  //   if (imageUrl) {
  //     const url = new URL(imageUrl);

  //     // Check if "sandbox" is present in the hostname
  //     if (url.hostname.includes("sandbox")) {
  //       // Remove "sandbox" from the hostname
  //       const modifiedHostname = url.hostname.replace("sandbox.", "");

  //       // Construct the modified URL
  //       const modifiedUrl = `${url.protocol}//${modifiedHostname}${url.pathname}${url.search}`;

  //       console.log(modifiedUrl);

  //       // Set isImage to the modified URL
  //       setIsImage(modifiedUrl);
  //     } else {
  //       console.log("Unable to find 'sandbox' in the hostname");
  //     }
  //   }
  // }, [filtertheEvent]);

  // console.log(isImage, "this is image");

  useEffect(() => {
    const seatingChartMedium =
      (filtertheEvent &&
        filtertheEvent[0]?.configuration?.seating_chart?.medium) ||
      (receivedData && receivedData[0]?.configuration?.seating_chart?.medium);
    const startSliceIndex = 31;
    const endSliceIndex = 39;

    if (seatingChartMedium) {
      const beforeSlice = seatingChartMedium.slice(0, startSliceIndex);
      const afterSlice = seatingChartMedium.slice(endSliceIndex);
      const remainingString = beforeSlice + afterSlice;
      setIsImage(remainingString);
    }
  }, [filtertheEvent]);
  // console.log(AllEvents);

  // ----- get coupon details from redux
  const CouponLoading = useSelector((state) => state.couponcode.CouponLoading);
  const singleCouponCode = useSelector(
    (state) => state.couponcode.singleCouponCode
  );

  return (
    <div className={`${isPayment ? "h-auto" : "h-[90vh]"}  index_groupdeatails `}>
      {/* <Header /> */}
      {/* ---- body  */}
      {
        !isPayment &&
        <div className="index_deatils  !bg-color1 !text-color2 h-[100vh]">
          {/* --- sidebar  */}
          <div className="!w-[390px] !h-[100%] !overflow-y-auto chactout_sidebar">
            <Sidebar
              filterevent={filtertheEvent}
              filtertheEvent={filtertheEvent}
              payWith={payWith}
              setPayWith={setPayWith}
              getOrderData={getOrderData}
              receivedData={receivedData}
              isImage={isImage}
              noChart={noChart}
              reviceImage={reviceImage}
              setIsPayment={setIsPayment}
              setNewSingleTicket={setSingleTicket}
              AllEventLoading={AllEventLoading}

            />
          </div>
          {/* --- content  */}
          <div className="w-full flex justify-center !text-color2 place-items-center h-[100%] py-5 px-5 ">
            <div className="parent_seat_map_wraper bg-color1  hide_image_div_in_mobile ">
              {AllEventLoading ? (
                <div>
                  <Loadingcom />
                </div>
              ) : (filtertheEvent &&
                filtertheEvent[0]?.configuration?.seating_chart?.large ==
                "null") ||
                (receivedData &&
                  receivedData[0]?.configuration?.seating_chart?.large ==
                  "null") ? (
                <img src={noChart} alt="no seating chart" className=" h-auto" />
              ) : (
                <img
                  src={isImage}
                  alt="Seating Chart Image"
                  className=" h-auto bg-color1 bg-opacity-0"
                />
              )}
            </div>
          </div>
        </div>
      }
      {
        isPayment &&
        <div className="z-[9999] bg-black ">
          <NewPaymentPage
            receivedData={receivedData}
            filterevent={filtertheEvent}
            SingleTicket={SingleTicket || receivedData}
            SetSingleTicket={setSingleTicket}
            payWith={payWith}
            setPayWith={setPayWith}
            getOrderData={getOrderData}
            setIsPayment={setIsPayment}
            data={data}
            singleCouponCode={singleCouponCode}
            setcoupon={setcoupon}
            coupon={coupon}
            RemoveCoupon={RemoveCoupon}
            ApplyCoupon={ApplyCoupon}
            CouponLoading={CouponLoading}
            PaymentInfoData={PaymentInfoData}
            setPaymentInfoData={setPaymentInfoData}


          />
        </div>
      }
      {/* ========================  */}
      {[1].includes(payWith) && (
        <div class="fixed z-[999] payment_modal left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-50 py-10">
          <div class="max-h-full w-full max-w-xl overflow-y-auto sm:rounded-2xl bg-white">
            <div class="relative w-full px-3">
              <h2 className="my-3  text-[20px] font-bold">Payment</h2>
              <div class="m-2 my-[30px] max-w-[800px] px-[20px] mx-auto  border-[1px]">
                {/* <OrderSummery
                  data={data}
                  singleCouponCode={singleCouponCode}
                  setcoupon={setcoupon}
                  coupon={coupon}
                  RemoveCoupon={RemoveCoupon}
                  ApplyCoupon={ApplyCoupon}
                  CouponLoading={CouponLoading} /> */}
                {/* =====  */}
                <div>
                  <RxCross1
                    className="absolute top-[5px] right-6 text-[23px] cursor-pointer text-color1"
                    onClick={() => setPayWith(0)}
                  />
                  <CreditCardPayment
                    show={payWith === 1 ? true : false}
                    totalamount={data && data?.retail_price * data?.qty}
                    onPaymentCompleted={async () => {
                      setPayWith(0);
                      await placeOrder();
                      setdata(null);
                      await dispatch(RemoveCoupon());

                    }}
                    setPaymentNonce={setPaymentNonce}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* ==== paypal  */}
      {[2].includes(payWith) && (
        <div class="fixed z-[29] payment_modal left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-50 py-10">
          <div class="max-h-full w-full max-w-xl overflow-y-auto sm:rounded-2xl bg-white">
            <div class="relative w-full px-3">
              <h2 className="my-3 text-[20px] font-bold">Payment</h2>

              <div class="m-2 my-[30px] max-w-[450px] mx-auto">
                {/* <OrderSummery
                  data={data}
                  singleCouponCode={singleCouponCode}
                  setcoupon={setcoupon}
                  coupon={coupon}
                  RemoveCoupon={RemoveCoupon}
                  ApplyCoupon={ApplyCoupon}
                  CouponLoading={CouponLoading} /> */}
                {/* =====  */}
                {/* =====  */}
                <RxCross1
                  className="absolute top-[5px] right-6 text-[23px] cursor-pointer text-color1"
                  onClick={() => setPayWith(0)}
                />

                <PayPalPayment
                  show={payWith === 2 ? true : false}
                  totalamount={data && data?.retail_price * data?.qty}
                  onPaymentCompleted={async () => {
                    setPayWith(0);
                    await placeOrder();
                    setdata(null);
                    await dispatch(RemoveCoupon());
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {/* =============  google pay*/}
      {[4].includes(payWith) && (
        <div class="fixed z-[29] payment_modal left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-50 py-10 ">
          <div class="max-h-full w-full max-w-xl overflow-y-auto sm:rounded-2xl bg-white ">
            <div class="relative w-full px-3">
              <h2 className="my-3  text-[20px] font-bold">Payment</h2>

              <div class="m-2 my-[30px] max-w-[450px] mx-auto">
                {/* <OrderSummery
                  data={data}
                  singleCouponCode={singleCouponCode}
                  setcoupon={setcoupon}
                  coupon={coupon}
                  RemoveCoupon={RemoveCoupon}
                  ApplyCoupon={ApplyCoupon}
                  CouponLoading={CouponLoading} /> */}
                {/* =====  */}
                {/* =====  */}
                <RxCross1
                  className="absolute top-[5px] right-6 text-[23px] cursor-pointer text-color1"
                  onClick={() => setPayWith(0)}
                />

                <GooglePay
                  show={payWith === 4 ? true : false}
                  totalamount={data && data?.retail_price * data?.qty}
                  onPaymentCompleted={async () => {
                    setPayWith(0);
                    await placeOrder();
                    setdata(null);
                    await dispatch(RemoveCoupon());

                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {/* =============== apple pay  */}
      {[3].includes(payWith) && (
        <div class="fixed z-[29] payment_modal left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-50 py-10">
          <div class="max-h-full w-full max-w-xl overflow-y-auto sm:rounded-2xl bg-white">
            <div class="relative w-full px-3">
              <h2 className="my-3 px-3 text-[20px] font-bold">Payment</h2>

              <div class="m-2 my-[30px] max-w-[450px] mx-auto">
                {/* <OrderSummery
                  data={data}
                  singleCouponCode={singleCouponCode}
                  setcoupon={setcoupon}
                  coupon={coupon}
                  RemoveCoupon={RemoveCoupon}
                  ApplyCoupon={ApplyCoupon}
                  CouponLoading={CouponLoading} /> */}
                {/* =====  */}
                {/* =====  */}
                <RxCross1
                  className="absolute top-[5px] right-6 text-[23px] cursor-pointer text-color1"
                  onClick={() => setPayWith(0)}
                />

                <ApplePay
                  show={payWith === 3 ? true : false}
                  totalamount={data && data?.retail_price * data?.qty}
                  onPaymentCompleted={async () => {
                    setPayWith(0);
                    await placeOrder();
                    setdata(null);
                    await dispatch(RemoveCoupon());
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default Index;



const OrderSummery = ({ data, singleCouponCode, setcoupon, coupon, RemoveCoupon, ApplyCoupon, CouponLoading }) => {

  const dispatch = useDispatch()


  return (
    <div className="my-3 flex flex-col gap-[12px] border-b-[1px] border-b-gray">
      <div className="flex justify-between place-items-center ">
        <h2 className="font-medium">Price/seat</h2>
        <h2 className="font-bold">{data?.retail_price}$</h2>
      </div>
      <div className="flex justify-between place-items-center">
        <h2 className="font-medium">Quantity</h2>
        <h2 className="font-bold">{data?.qty}</h2>
      </div>
      <div className="flex justify-between place-items-center">
        <h2 className="font-medium">Service fee</h2>
        <h2 className="font-bold">{data?.service_fee}$</h2>
      </div>
      <div className="flex justify-between place-items-center">
        <h2 className="font-medium">Subtotal</h2>
        <h2 className="font-bold">
          {data?.retail_price} X {data?.qty} ={" "}
          {data?.retail_price * data?.qty}
        </h2>
      </div>
      <div className="flex justify-between place-items-center">
        <h2 className="font-medium">Discount</h2>
        <h2 className="font-bold">
          {singleCouponCode && singleCouponCode?.discount
            ? singleCouponCode?.discount
            : "$0"}
        </h2>
      </div>
      <div className="flex justify-between place-items-center">
        <h2 className="font-medium">Total</h2>
        <h2 className="font-bold">
          {singleCouponCode && singleCouponCode?.discount
            ? data?.retail_price * data?.qty +
              data?.service_fee -
              singleCouponCode?.discount <=
              0
              ? 0
              : data?.retail_price * data?.qty +
              data?.service_fee -
              singleCouponCode?.discount
            : data?.retail_price * data?.qty + data?.service_fee}
          $
        </h2>
      </div>
      <div className="flex justify-between place-items-center flex-col">
        {/* <p className="text-left w-full font-medium">
        Apply Coupon Code
      </p> */}
        <div className="flex justify-start place-items-center gap-0 w-[100%] my-2 border-[1px] border-color4 rounded-md">
          <div className="flex w-[100%] place-items-center px-1">
            <input
              type="text"
              Placeholder="Enter coupon code"
              className="w-[100%] px-2 py-2 bg-transparent outline-none"
              value={coupon}
              onChange={(e) => setcoupon(e.target.value)}
            />
            <p
              className="text-[18px] pr-1 cursor-pointer"
              onClick={() => {
                dispatch(RemoveCoupon());
                setcoupon("");
              }}
            >
              X
            </p>
          </div>
          {CouponLoading ? (
            <button className="bg-black text-white cursor-pointer py-2 px-4">
              <Spinner />
            </button>
          ) : (
            <button
              className="bg-black text-white cursor-pointer py-2 px-4"
              onClick={async () => {
                await dispatch(ApplyCoupon(coupon));
              }}
            >
              Apply
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

import React, { useState } from "react";
import { CiSearch } from "react-icons/ci";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ApiMediaURL } from "../../setting/GlobleVariable";
import { RxCross1 } from "react-icons/rx";
import { VscMenu } from "react-icons/vsc";
import {  LogoutUserFunc } from "../../redux/actions/User";
import { RxHamburgerMenu } from "react-icons/rx";
import { SearchEvents } from "../../redux/actions/Events";
import { data } from "../CategorySection/DataCategory";
// import logo from "../../assets/InstaPass.png";
import Logo from "../Logo/Logo";
import "./styles/navebar.css";

const Navebar = () => {
  const UserLocationdata = useSelector((state) => state.user.userlocation);
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showmenu, setshowmenu] = useState(false);
  const [search, setSearch] = useState("");
  const [hover, setHover] = useState(false)
  const [subId, setShowSubId] = useState(1)
  const matchedItem = data?.find((item) => item.id === subId);
  const UserAvatar = ApiMediaURL + user?.Avatar;


  const searchFunction = () => {
    navigate(`tickets/${search}`);
    setshowmenu(false);
    dispatch(SearchEvents(search, UserLocationdata));
  };



  return (
    <>
      {
        hover &&
        <div class="fixed inset-0 bg-[black] opacity-[.7] z-[20]"></div>

      }

      <div className="z-[100] new_header bg-color1 text-color2 flex w-full gap-5  justify-between place-items-center py-[25px] px-[10px] 2xl:px-[100px] max-w-[2000px] mx-auto">

        {/* ------- left header  */}
        <div className="mob_header w-full ">
          <div className="w-full flex justify-between place-items-center gap-3 ">
            <Logo />

            <div className="searchbarbox tab_searchbarbox flex w-full justify-start place-items-center rounded-[10px] gap-2 py-[6px] px-3">
              <CiSearch
                className="text-[18px] cursor-pointer"
                onClick={() => searchFunction()}
              />
              <input
                className="bg-transparent outline-none text-color5 w-full"
                type="text"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(`tickets/${search}`);
                  }
                }}
              />
            </div>
            <div>
              <div className="w-full">
                <RxHamburgerMenu
                  className="burger text-[28px] mt-[-1px] cursor-pointer"
                  onClick={() => setshowmenu(true)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="right_header  flex justify-start place-items-center">
          <Logo />
        </div>
        {/* ------ right header  */}
        <div className="right_header flex  justify-end place-items-end gap-[60px] 2xl:gap-[80px] w-full pr-[40px]">
          {/* ---- menus  */}
          <div className="new_menus flex w-full justify-end place-items-center">
            <NavLink to="/">
              <li>Home</li>
            </NavLink>
            <NavLink to="/tickets">
              <li>All Events</li>
            </NavLink>
            <NavLink to="/newsfeed/1">
              <li>News Feed</li>
            </NavLink>
            <NavLink to="/aboutUs">
              <li>About Us</li>
            </NavLink>

            <div className="dropdown dropdown-hover !z-[10]"
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >

              <p tabIndex={0}>
                <li>Categories</li>
              </p>
              <ul tabIndex={0} className="dropdown-content rounded-lg menu bg-[black] z-[1] w-[400px] p-2 shadow"
                style={{
                  boxShadow: `0px 0px 15px 1px rgba(205, 41, 46, 0.45)`
                }}
              >
                <div className=" max-h-[450px] overflow-y-hidden category_parent"
                  style={{
                    display: "grid",
                    gridTemplateColumns: ".7fr 1fr"
                  }}
                >
                  <div className="border-r-[1px] border-r-[#1f1e1e] h-[100%] overflow-y-auto ">
                    {
                      data?.map((item, index) => {
                        return (
                          <li key={index} className={`${subId === item.id && "bg-[#1a1818]"} hover:bg-[#131212] border-b-[1px] border-b-[#1f1e1e] rounded-none`}
                            onMouseEnter={() => {
                              setShowSubId(item.id);
                            }}
                          ><p>{item.name}</p></li>
                        )
                      })
                    }
                  </div>
                  <div className="h-[100%] overflow-y-auto category_parent">
                    {matchedItem && matchedItem.subCategory && matchedItem.subCategory.length > 0 ? (
                      matchedItem.subCategory.map((item, index) => (
                        <li key={index} className="hover:bg-[#131212] border-b-[1px] border-b-[#131212]">
                          <NavLink to={`/tickets/category=${item?.id}`}>{item.name}</NavLink>
                        </li>
                      ))
                    ) : (
                      <div className="new_category_box shrink-0 w-[100%] hover:border-b-2 hover:border-b-redColor cursor-pointer">
                        <p className="w-[100%] text-center">No more category found</p>
                      </div>
                    )}
                  </div>
                </div>
              </ul>
            </div>
            {user && user?.email && (
              <div
                onClick={() => {
                  navigate(`/user/profile`, {
                    state: { data: 1 },
                  });
                }}
              >
                <li>My tickets</li>
              </div>
            )}
          </div>
          {/* ---- search bar and btn  */}
          <div className="search_area_btn flex  justify-center place-items-center gap-[10px]">
            <div className="searchbarbox flex w-full justify-center place-items-center rounded-[10px] gap-2 py-[6px] px-3">
              <CiSearch
                className="text-[18px] cursor-pointer"
                onClick={() => searchFunction()}
              />
              <input
                className="bg-transparent outline-none text-color5"
                type="text"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(`tickets/${search}`);
                  }
                }}
              />

            </div>
            {user && user?.firstName ? (
              user && user?.Avatar ? (
                <NavLink to={"/user/profile"} className="w-[50px] h-[40px]">
                  <img
                    className="w-[50px] h-[40px] object-cover uppercase font-bold rounded-full border-2 border-redColor flex justify-center items-center ml-[10px]"
                    src={`${UserAvatar}`}
                    alt={`Avatar`}
                  />
                </NavLink>
              ) : (
                <NavLink to="/user/profile" className="w-[50px] h-[40px]">
                  <p className="w-[40px] h-[40px] uppercase font-bold rounded-full border-2 border-redColor flex justify-center items-center ml-[10px]">
                    {user?.firstName.slice(0, 1)}
                  </p>
                </NavLink>
              )
            ) : (
              <>
                <NavLink to="/registration">
                  <button className="account_btn1">SignUp</button>
                </NavLink>
                <NavLink to="/login">
                  <button className="account_btn2">SignIn</button>
                </NavLink>
              </>
            )}
          </div>
        </div>
      </div>
      {/* ========================= */}
      <div
        className={`${showmenu ? "mobileHeader header_menus" : "header_menus"
          }  bg-black text-color2 py-2 px-2 overflow-y-auto !z-[999999999999999999]`}
      >
        <RxCross1
          className="cross_burger absolute top-5 right-5 text-[23px] cursor-pointer  hidden"
          onClick={() => setshowmenu(false)}
        />
        <div className="searchbarbox my-4  flex w-full justify-start place-items-center rounded-[10px] gap-2 py-[15px] px-3">
          <CiSearch
            className="text-[20px] cursor-pointer"
            onClick={() => searchFunction()}
          />
          <input
            className="bg-transparent outline-none text-color5 w-full"
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                navigate(`tickets/${search}`);
                setshowmenu(false);
              }
            }}
          />
        </div>
        <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center sidebar_nav ">
          <NavLink
            to={"/"}
            className={`mr-5 hover:text-redColor active:text-[red]`}
            onClick={() => setshowmenu(false)}
          >
            Home
          </NavLink>
          <NavLink
            to={"/tickets"}
            className={`mr-5 hover:text-redColor`}
            onClick={() => setshowmenu(false)}
          >
            All Events
          </NavLink>
          <NavLink
            onClick={() => setshowmenu(false)}
            to="/newsfeed/1"
            className={`mr-5 hover:text-redColor active:text-[red]`}
          >
            News Feed
          </NavLink>
          <NavLink
            to={"/location"}
            className={`mr-5 hover:text-redColor active:text-[red]`}
            onClick={() => setshowmenu(false)}
          >
            Location
          </NavLink>
          <NavLink
            to={"/aboutUs"}
            className={`mr-5 hover:text-redColor active:text-[red]`}
          >
            About Us
          </NavLink>
          <NavLink>
            {user && user?.email && (
              <div
                onClick={() => {
                  navigate(`/user/profile`, {
                    state: { data: 1 },
                  });
                }}
              >
                <li className="list-none ">My tickets</li>
              </div>
            )}
          </NavLink>
          {/* ============ categories accordian  */}

          {
            data?.map((item, index) => {
              return (
                <div className="collapse collapse-plus bg-transparent" key={index}>
                  <input type="radio" name="my-accordion-3" defaultChecked />
                  <div className="collapse-title text-xl font-medium">{item?.name}</div>
                  <div className="collapse-content bg-[#131212]">
                    {
                     item?.subCategory?.length > 0 ? item?.subCategory?.map((elem,index)=>{
                        return (
                          <li key={index} className="hover:bg-[#131212] list-none py-2 w-[100%]">
                          <NavLink to={`/tickets/category=${elem?.id}`} className="w-[100%] text-[grey] !border-none block">{elem.name}</NavLink>
                      </li>
                        )
                      })
                      :
                      <div className="new_category_box shrink-0 w-[100%] hover:border-b-2 hover:border-b-redColor cursor-pointer">
                      <p className="w-[100%] text-center">No more category found</p>
                    </div>
                    }
                  </div>
                </div>
              )
            })
          }


          {/* ==========================  */}
        </nav>
        {/* =====  */}
        <div className=" hidden my-5  justify-start place-items-center gap-1 w-[100%] login_mobile">
          {Object.keys(user).length === 0 ? (
            <NavLink to="/login" onClick={() => setshowmenu(false)}>
              <button className="w-full items-center  hover:text-color1 py-3 !bg-redColor px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0">
                SignIn / SignUp
              </button>
              <VscMenu className="text-[23px] cursor-pointer  hidden " />
            </NavLink>
          ) : (
            <>
              {user && user?.Avatar ? (
                <>
                  <NavLink
                    to="/user/profile"
                    onClick={() => setshowmenu(false)}
                    className="flex justify-start !place-items-center text-color2 "
                  >
                    <div className="!w-[60px] !h-[60px] flex justify-center place-items-center">
                      {user?.firstName && user?.Avatar && (
                        <img
                          src={UserAvatar}
                          className="!w-[40px] !h-[40px] rounded-full border-[1px] border-redColor"
                          alt=""
                        />
                      )}
                    </div>
                    <h2 className="mx-2 !text-[20px] !text-color2">
                      {user?.firstName}
                    </h2>
                  </NavLink>
                </>
              ) : (
                <NavLink
                  to="/user/profile"
                  className=" flex justify-start !place-items-center text-color2"
                >
                  <p
                    onClick={() => setshowmenu(false)}
                    className="border-[1px] uppercase border-redColor rounded-full p-3 h-[40px] w-[40px] flex justify-center place-items-center bg-color1 text-[white] font-bold"
                  >
                    {user?.firstName?.slice(0, 1)}
                  </p>
                  <h2 className="mx-2 !text-[20px] !text-color2">
                    {user?.firstName}
                  </h2>
                </NavLink>
              )}
            </>
          )}
          {user && user?.firstName && (
            <button
              className="my-4 py-2 px-3 border-[1px] border-redColor w-full rounded-md"
              onClick={() => dispatch(LogoutUserFunc(navigate))}
            >
              Logout
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Navebar;

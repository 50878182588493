import React, { useState } from "react";
import { useSelector } from "react-redux";
import CheckOutSidebar from "./CheckOutSidebar";
import Spinner from "../../../Loading/Spinner";
import { ReactSVG } from "react-svg";
import locationSVG from "../../../../assets/svg/location-dot.svg";
import CalenderSVG from "../../../../assets/svg/calendar.svg";
import ClockSVG from "../../../../assets/svg/clock.svg";
import DollarSVG from "../../../../assets/svg/circle-dollar1.svg";
import TicketSVG from "../../../../assets/svg/Ticket Icon.svg";
import bookingSVG from "../../../../assets/svg/Booking Icon.svg";
import Loading from "../../../Loading/Loading";
import { useParams } from "react-router-dom";

const Sidebar = ({
  filterevent,
  filtertheEvent,
  payWith,
  setPayWith,
  getOrderData,
  receivedData,
  isImage,
  noChart,
  reviceImage,
  setIsPayment,
  setNewSingleTicket,

}) => {
  const GroupsEvents = useSelector((state) => state.events.EventDetails);
  const AllEventLoading = useSelector((state) => state.events.AllEventLoading);
  const [isFilterEventLoading, setisFilterEventLoading] = useState(true);  
  const params = useParams();
  const [active, setActive] = useState(1);
  const SibgleEventLoading = useSelector(
    (state) => state.events.SibgleEventLoading
  );
  const [SingleTicket, setSingleTicket] = useState([]);
  const clickOnButton = (id) => {
    const filterSingleEvent =
      // eslint-disable-next-line
      GroupsEvents && GroupsEvents?.filter((item) => item.id == id);
    setSingleTicket(filterSingleEvent);
    setNewSingleTicket(filterSingleEvent)
  };


  // const TypeEvent =
  //   GroupsEvents && GroupsEvents?.filter((item) => item.type === "event");
  const TypeParking =
    GroupsEvents && GroupsEvents?.filter((item) => item.type === "parking");

  setTimeout(() => {
    setisFilterEventLoading(false);
  }, 2000);

  return (
    <>
      <div className="!bg-transparent !bg-black  relative py-5 px-1">
        {/* --------------------  */}
        <div
          className={` ${SingleTicket && SingleTicket?.length > 0
            ? "left-[0%] duration-500"
            : "!left-[-150%] duration-500"
            }  `}
        >
          <CheckOutSidebar
            SingleTicket={SingleTicket || receivedData}
            SetSingleTicket={setSingleTicket}
            payWith={payWith}
            setPayWith={setPayWith}
            getOrderData={getOrderData}
            setIsPayment={setIsPayment}

          />
        </div>
        {/* --head  */}
        {SingleTicket && SingleTicket?.length === 0 && (
          <div className="deatils_sidebar">
            <div className=" px-3">
              <img src={reviceImage} alt="" className="my-[10px] rounded-md w-[100%] max-h-[300px]" />
              <h2 className="mb-1 text-[23px] text-color2 font-medium evet_namesidebar">
                {(filterevent && filterevent[0]?.name) ||
                  (receivedData && receivedData[0]?.name) || params?.name}
              </h2>
              {/* <p className="text-[13px] text-color5 evet_parasidebar">
              InstaPass is a ticket marketplace that puts fans first. All
              tickets are protected by our <span>Fan Promise</span>.
            </p> */}
              <div className="flex justify-start place-items-center gap-[10px] my-3 flex-wrap">
                <div className="text-[13px] text-color2 my-2 font-bold location_div">
                  <ReactSVG src={locationSVG} />
                  <span className="text-color5 mx-1">
                    {(filterevent && filterevent[0]?.venue?.location) ||
                      (receivedData && receivedData[0]?.venue?.location) || params?.loc}
                  </span>
                </div>
                <div className="text-[13px] text-color2 my-2 font-bold location_div">
                  <ReactSVG src={CalenderSVG} />
                  <span className="text-color5 mx-1">
                    {(filterevent &&
                      filterevent[0]?.occurs_at_local?.slice(0, 10)) ||
                      (receivedData &&
                        receivedData[0]?.occurs_at_local?.slice(0, 10)) || params?.date?.slice(0, 10)}
                  </span>
                </div>

                <div className="text-[13px] text-color2 my-2 font-bold location_div">
                  <ReactSVG src={ClockSVG} />
                  <span className="text-color5 mx-1">
                    {(filterevent &&
                      filterevent[0]?.occurs_at_local?.slice(-4)) ||
                      (receivedData &&
                        receivedData[0]?.occurs_at_local?.slice(-4)) || params?.date?.slice(-4)}
                  </span>
                </div>
              </div>
            </div>
            {/* ----- image   */}
            <div className="parent_seat_map_wraper bg-color1 siebar_image_div ">
              {AllEventLoading ? (
                <div>
                  <Loading />
                </div>
              ) : (filtertheEvent &&
                // eslint-disable-next-line
                filtertheEvent[0]?.configuration?.seating_chart?.large ==
                "null") ||
                (receivedData &&
                  // eslint-disable-next-line
                  receivedData[0]?.configuration?.seating_chart?.large ==
                  "null") ? (
                <img src={noChart} alt="no seating chart" className=" h-auto" />
              ) : (
                <img
                  // src={filtertheEvent[0]?.configuration?.seating_chart?.medium}
                  src={isImage}
                  alt="Seating Chart"
                  className=" h-auto bg-color1 bg-opacity-0"
                />
              )}
            </div>

            {/* ------  */}
            {SibgleEventLoading ? (
              <div className="w-full flex justify-center place-items-center my-3">
                <Spinner />
              </div>
            ) : (
              <div className=" py-2 px-2 mb-[90px]">
                <div className="flex justify-around place-items-center gap-2 px-[10px] my-2">
                  <h2
                    className={` mb-2 font-medium allticket_sidebar_text cursor-pointer ${active === 1
                      ? "!text-redColor border-b-[1px] border-b-redColor pb-1"
                      : "!text-color5"
                      }`}
                    onClick={() => setActive(1)}
                  >
                    All Tickets
                  </h2>
                  <h2
                    className={`text-color5 mb-2 font-medium allticket_sidebar_text cursor-pointer ${active === 2
                      ? "!text-redColor border-b-[1px] border-b-redColor pb-1"
                      : "!text-color5"
                      }`}
                    onClick={() => setActive(2)}
                  >
                    Parking
                  </h2>
                </div>
                <div className=" px-2 py-2 rounded-md sidebrticket_box">
                  {/* ---- price  */}
                  {GroupsEvents && active === 1 ? (
                    GroupsEvents?.map((item, index) => {
                      if (item?.type === "parking") {
                        return;
                      }
                      return (
                        <div
                          key={index}
                          className="py-2 border-b-[1px] border-b-shadowColor my-2 flex justify-between gap-3 place-items-center cursor-pointer hover:border-[1px] hover:border-redColor px-1 "
                          onClick={() => {clickOnButton(item?.id); console.log(item,  "this is click item id")}}
                        >
                          <h2 className="px-1 sidebar_price_tikcet py-1  rounded-md font-medium text-[18px]">
                            <span>
                              <ReactSVG src={DollarSVG} />
                            </span>
                            {item?.retail_price}
                          </h2>
                          <div className="w-full">
                            <h2 className="mb-1 font-bold sidebar_ticket_name">
                              Section {item?.section} - Row {item?.row}{" "}
                            </h2>
                            <p className="text-color5 ticket_available_count">
                              {item?.available_quantity} tickets
                              <span>
                                <ReactSVG src={TicketSVG} />
                              </span>
                            </p>
                          </div>
                          {/* <IoIosArrowForward className="text-[35px] text-color5" /> */}
                          <div>
                            <ReactSVG src={bookingSVG} />
                          </div>
                        </div>
                      );
                    })
                  ) : TypeParking?.length > 0 ? (
                    TypeParking?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="py-2 border-b-[1px] border-b-shadowColor my-2 flex justify-between gap-3 place-items-center cursor-pointer hover:border-[1px] hover:border-redColor px-1 "
                          onClick={() => clickOnButton(item?.id)}
                        >
                          <h2 className="px-1 sidebar_price_tikcet py-1  rounded-md font-medium text-[18px]">
                            <span>
                              <ReactSVG src={DollarSVG} />
                            </span>
                            {item?.retail_price}
                          </h2>
                          <div className="w-full">
                            <h2 className="mb-1 font-bold sidebar_ticket_name">
                              Section {item?.section} - Row {item?.row}{" "}
                            </h2>
                            <p className="text-color5 ticket_available_count">
                              {item?.available_quantity} tickets
                              <span>
                                <ReactSVG src={TicketSVG} />
                              </span>
                            </p>
                          </div>
                          {/* <IoIosArrowForward className="text-[35px] text-color5" /> */}
                          <div>
                            <ReactSVG src={bookingSVG} />
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-center my-3 text-[15px]">
                      No parking available
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>


    </>
  );
};

export default Sidebar;


